import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrStorage from '@/modules/common/services/storage.service'
import Container from 'typedi'
import { Component, Vue } from 'vue-property-decorator'
import GrtSecurityLayout from '../../layouts/security/security.layout.vue'

@Component({
  name: 'GtrActivateAccountView'
})
export default class GtrActivateAccountView extends Vue {
  data () {
    return {
      loading: false
    }
  }

  created () {
    this.$emit('update:layout', GrtSecurityLayout)
  }

  async mounted () {
    const activationToken = this.$route.params.token
    if (activationToken) {
      try {
        this.$data.loading = true
        const response = await this.$store.dispatch('security/activateAccount', activationToken)
        Container.get(GtrStorage).removeItem('signedup_email')
        if (response.data.user.password_change_required) {
          Container.get(GtrStorage).setItem(
            'security_context',
            JSON.stringify({
              access_token: response.data.access_token,
              access_level: response.data.user.access_level,
              user: response.data.user,
              company: response.data.user.company,
              expires_in: response.data.expires_in,
              has_company: response.data.user.company !== null
            })
          )
          this.$router.push({
            name: 'security.password_setup',
            params: {
              token: activationToken
            }
          })
        }
      } catch (error) {
        this.$router.push({ name: 'security.login' }, () => {
          Container.get(ErrorHandlerService).error(error)
        })
      } finally {
        this.$data.loading = false
      }
    } else {
      this.$router.push({ name: 'security.login' })
    }
  }
}
